<template>
	<figure class="course-editor-block-container">
		<p v-for="el in paragraphs" :key="el.id" v-html="doMark(el.content)"></p>
	</figure>
</template>

<script>
import marked from 'marked'

export default {
	name: "CourseEditorBlockParagraph",
	props: {
		paragraphs: {
			type: Array,
			require: true
		}
	},
	methods: {
		doMark(e) {
			return marked(e)
		}
	}
};
</script>

<style></style>
